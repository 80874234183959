import { Modal, Box } from '@mui/material';

interface DemoModalProps {
    open: boolean;
    onClose: () => void;
}

export default function DemoModal({ open, onClose }: DemoModalProps) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="book-demo-modal"
            aria-describedby="book-a-demo-calendly"
            closeAfterTransition
            slotProps={{
                backdrop: {
                    sx: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
                }
            }}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                height: '80vh',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 0,
            }}>
                <iframe
                    src="https://calendly.com/daniel-orgatlas/30min"
                    width="100%"
                    height="100%"
                />
            </Box>
        </Modal>
    );
} 