import type { } from '@mui/material/themeCssVarsAugmentation';
import { alpha } from '@mui/material/styles';
import { createTheme } from '@mui/material';
import '../assets/css/font/style.css'

const blue = '#0097B2'
const caribbean_current = '#1C6B79'
const onyx = '#373E40'
const battleship_gray = '#969797'
const isabelline = '#F5EFED'
const asparagus = '#7FB069'
const pumpkin = '#E77728'
const tomato = '#EF6351'

export const theme = createTheme({
    palette: {
        primary: {
            main: blue,
            dark: caribbean_current,
        },
        secondary: {
            main: onyx,
            light: battleship_gray,
        },
        warning: {
            main: pumpkin,
        },
        error: {
            main: tomato
        },
        success: {
            main: asparagus
        },
        text: {
            primary: onyx,
            secondary: isabelline,
        },
        background: {
            default: isabelline,
            paper: onyx,
        }
    },
    typography: {
        fontFamily: ['"Helvetica World"'].join(','),
        h1: {
            fontSize: 60,
            fontWeight: 600,
            lineHeight: 78 / 70,
            letterSpacing: -0.2,
        },
        h2: {
            fontSize: 48,
            fontWeight: 600,
            lineHeight: 1.2,
        },
        h3: {
            fontSize: 42,
            lineHeight: 1.2,
        },
        h4: {
            fontSize: 36,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        h5: {
            fontSize: 20,
            fontWeight: 600,
        },
        h6: {
            fontSize: 18,
        },
        subtitle1: {
            fontSize: 18,
        },
        subtitle2: {
            fontSize: 16,
        },
        body1: {
            fontWeight: 400,
            fontSize: 15,
        },
        body2: {
            fontWeight: 400,
            fontSize: 14,
        },
        caption: {
            fontWeight: 400,
            fontSize: 12,
        },
    }
});


theme.components = {
    MuiCssBaseline: {
        styleOverrides: {
            html: {
                scrollBehavior: 'smooth',
            },
        },
    },
    MuiTable: {
        styleOverrides: {
            root: {
                border: '10px solid ' + theme.palette.secondary.main,
                borderBottom: '20px solid ' + theme.palette.secondary.main,
            }
        }
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                color: theme.palette.secondary.main,
            },
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                backgroundColor: theme.palette.background.default,
            }
        }
    },
    MuiTableContainer: {
        styleOverrides: {
            root: {
                borderRadius: '10px',
            }
        }
    },
    MuiTableHead: {
        styleOverrides: {
            root: {
                backgroundColor: theme.palette.secondary.main,
                textTransform: 'capitalize',
            },
        }
    },

    MuiTableBody: {
        styleOverrides: {
            root: {
                backgroundColor: theme.palette.background.default,
                borderRadius: '10px',
            }
        }
    },
    MuiTab: {
        styleOverrides: {
            root: {
                border: 'none',
                borderRadius: '10px',
                fontFamily: "Helvetica World",
                fontSize: 20,
                fontWeight: 600,
                textTransform: 'none',
                color: '#fff',
                '&.Mui-selected': {
                    color: '#fff',
                    backgroundColor: theme.palette.primary.main,
                },
            },
        },
    },
    MuiButtonBase: {
        defaultProps: {
            disableTouchRipple: true,
            disableRipple: true,
        },
        styleOverrides: {
            root: {
                boxSizing: 'border-box',
                transition: 'all 100ms ease-in',
                '&:focus-visible': {
                    outline: `3px solid ${alpha(theme.palette.primary.main, 0.5)}`,
                    outlineOffset: '2px',
                },
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: ({ theme, ownerState }) => ({
                boxSizing: 'border-box',
                boxShadow: 'none',
                borderRadius: '10px',
                textTransform: 'none',
                '&:active': {
                    transform: 'scale(0.98)',
                },
                ...(ownerState.size === 'small' && {
                    maxHeight: '32px',
                }),
                ...(ownerState.size === 'medium' && {
                    height: '40px',
                }),
                ...(ownerState.variant === 'contained' &&
                    ownerState.color === 'primary' && {
                    color: theme.palette.primary.main,
                    background: theme.palette.secondary.main,
                    boxShadow: `inset 0 1px ${alpha(theme.palette.primary.main, 0.4)}`,
                    '&:hover': {
                        background: theme.palette.primary.dark,
                        backgroundImage: 'none',
                        boxShadow: `0 0 0 1px  ${alpha(theme.palette.primary.main, 0.5)}`,
                    },
                }),
                ...(ownerState.variant === 'outlined' && {
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    borderColor: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                    '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.3),
                        borderColor: theme.palette.primary.main,
                    },
                }),
                ...(ownerState.variant === 'text' && {
                    color: theme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.3),
                        borderColor: theme.palette.primary.main,
                    },
                }),
            }),
        },
    },
    MuiCard: {
        styleOverrides: {
            root: ({ theme, ownerState }) => ({
                backgroundColor: theme.palette.primary.main,
                borderRadius: 10,
                padding: '20px',
                textAlign: 'left',
                boxShadow: 'none',
                color: theme.palette.text.primary,
                transition: 'background-color, border, 80ms ease',
                ...(ownerState.variant === 'outlined' && {
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    borderColor: theme.palette.primary.main,
                    borderWidth: '3px',
                }),
            }),
        },
    },
    MuiTextField: {
        styleOverrides: {
            root: ({ theme, ownerState }) => ({
                borderRadius: 10,
                boxShadow: 'none',
                ...(ownerState.variant === 'outlined' && {
                    background: theme.palette.background.default,
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    }
                }),
            }),
        },
    },

    MuiDivider: {
        styleOverrides: {
            root: ({ theme }) => ({
                borderColor: `${alpha(theme.palette.secondary.main, 0.8)}`,
            }),
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: ({ theme }) => ({
                backgroundImage: 'none',
                backgroundColor: theme.palette.secondary.main,
            }),
        },
    }
}