import { Typography, Box } from '@mui/material';

export const ArticleHeading = ({ children }: { children: React.ReactNode }) => (
    <Typography variant="h4" component="h2" sx={{
        mt: 4,
        px: { xs: '20px', sm: '150px' },
        textAlign: 'left'
    }}>
        {children}
    </Typography>
);

export const ArticleSubheading = ({ children }: { children: React.ReactNode }) => (
    <Typography variant="h5" component="h2" sx={{
        mt: 4,
        px: { xs: '20px', sm: '150px' },
        textAlign: 'left'
    }}>
        {children}
    </Typography>
);

export const ArticleBody = ({ children }: { children: React.ReactNode }) => (
    <Typography variant="h6" component="h2" sx={{
        mt: 4,
        px: { xs: '20px', sm: '150px' },
        textAlign: 'left'
    }}>
        {children}
    </Typography>
);

export const ArticleQuote = ({ children, author }: { children: React.ReactNode, author?: string }) => (
    <Box sx={{
        mx: { xs: '20px', sm: '150px' },
        my: 6,
        pr: '40px',
        pl: '8',
        borderLeft: '32px solid',
        borderColor: 'primary.main',
        backgroundColor: 'background.paper',
        color: 'text.secondary',
        py: 3,
    }}>
        <Typography variant="h4" component="blockquote" sx={{
            fontStyle: 'italic',
            pl: 4,
            mb: author ? 2 : 0,
        }}>
            "{children}"
        </Typography>
        {author && (
            <Typography variant="subtitle1" sx={{
                pl: 4,
                color: 'text.secondary',
            }}>
                — {author}
            </Typography>
        )}
    </Box>
); 