import { Typography, Box } from '@mui/material'
import { useTheme } from "@mui/material/styles";

export default function About() {
    const theme = useTheme();

    return (
        <Box minHeight={'100vh'}
            id={'about'}
            sx={{
                'backgroundColor': theme.palette.background.default, display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center'
            }}>
            <Box
                sx={{
                    maxWidth: '70%',
                    margin: '0 auto',
                    pt: '130px'
                }}>

                <Typography variant="h3" component="h2"
                    sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                    our inspiration.
                </Typography>
                <Typography variant="body1" component="h2"
                    sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                    OrgAtlas is inspired by the organisational behavioural theories of transactive memory and group mind.
                </Typography>
                <Typography variant="body1" component="h2"
                    sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                    As organisations become increasingly large, complex and geographically distributed, silos develop.
                </Typography>

                <Typography variant="body1" component="h2"
                    sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                    This makes it harder to know where to locate knowledge, and who to speak to.
                </Typography>

                <Typography variant="body1" component="h2"
                    sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                    We've experienced the overheads and inefficiencies associated with these problems first hand, and
                    are inspired to improve them.
                </Typography>


            </Box>
        </Box>
    )
        ;
}