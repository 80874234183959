import * as React from 'react';
import { Typography, Box, Slider, IconButton, List, ListItem, ListItemButton, Grid2 } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { useState, useRef } from "react";
import ReactPlayer from 'react-player/file';
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute, FaExpand } from "react-icons/fa";
import signup from '../assets/tutorials/signup.mp4';
import uploading from '../assets/tutorials/uploading.mp4';
import searching from '../assets/tutorials/searching.mp4';


interface TutorialLinkProps {
    item: string,
    label: string
    selection: string
    setSelection: Function
    setVideo: Function
}

function TutorialLink(props: TutorialLinkProps) {
    const theme = useTheme();

    return (<ListItem disablePadding>
        <ListItemButton
            sx={{
                backgroundColor: props.selection === props.item ? theme.palette.primary.main : theme.palette.secondary.main,
                '&:hover': { backgroundColor: theme.palette.primary.dark },
            }}
            onClick={() => {
                props.setSelection(props.item);
                props.setVideo()
            }}
        >
            <Typography variant="h6"
                color={props.selection === props.item ? theme.palette.text.primary : theme.palette.text.secondary}
                sx={{ fontWeight: props.selection === props.item ? 'bold' : 'regular' }}
            >
                {props.label}
            </Typography>
        </ListItemButton>
    </ListItem>);
}

export default function Tutorials() {
    const theme = useTheme();
    const [playing, setPlaying] = useState<boolean>(false);
    const [volume, setVolume] = useState<number>(0.8);
    const [muted, setMuted] = useState<boolean>(false);
    const [played, setPlayed] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [selection, setSelection] = useState<string>('sign_up');
    const [currentVideo, setCurrentVideo] = useState<any>(signup);


    // ReactPlayer instance reference with correct typing
    const playerRef = useRef<ReactPlayer | null>(null);

    const togglePlayPause = () => {
        setPlaying(!playing);
    };

    const handleVolumeChange = (event: Event, newValue: number | number[]) => {
        const volumeValue = newValue as number;
        setVolume(volumeValue);
        setMuted(volumeValue === 0);
    };

    const handleProgress = (state: { played: number }) => {
        setPlayed(state.played); // Track progress in real-time
    };

    const handleSeekChange = (event: Event, newValue: number | number[]) => {
        const seekValue = newValue as number;
        setPlayed(seekValue);
        if (playerRef.current) {
            playerRef.current.seekTo(seekValue);
        }
    };

    const handleDuration = (duration: number) => {
        setDuration(duration); // Set duration once video metadata is loaded
    };

    return (
        <Box minHeight={'100vh'} sx={{
            display: 'flex-block', justifyContent: "center",
            'backgroundColor': theme.palette.background.default, position: 'relative', alignItems: 'center',
        }} id='tutorials'>

            <Box display="flex"
                sx={{ mx: '10%', pt: '150px', height: '70%', justifyContent: 'center', alignItems: 'center' }}>
                <Grid2 container>
                    {/* Content Bar */}
                    <Grid2 size={{ xs: 12, md: 3 }} bgcolor={theme.palette.secondary.main}>
                        <List>
                            <TutorialLink
                                item={'sign_up'}
                                label={'Signing Up'}
                                selection={selection}
                                setSelection={setSelection}
                                setVideo={() => setCurrentVideo(signup)} />
                            <TutorialLink
                                item={'searching'}
                                label={'Search Basics'}
                                selection={selection}
                                setSelection={setSelection}
                                setVideo={() => setCurrentVideo(searching)} />
                            <TutorialLink
                                item={'uploading'}
                                label={'Upload Content'}
                                selection={selection}
                                setSelection={setSelection}
                                setVideo={() => setCurrentVideo(uploading)} />

                        </List>
                    </Grid2>

                    {/* Video Player with Control Bar */}
                    <Grid2 size={{ xs: 12, md: 9 }}>
                        <ReactPlayer
                            ref={playerRef}
                            url={currentVideo}
                            playing={playing}
                            volume={volume}
                            muted={muted}
                            onProgress={handleProgress}
                            onDuration={handleDuration}
                            width="100%"
                            height="93%"
                        />

                        {/* Control Bar */}
                        <Box bgcolor={theme.palette.primary.main} display="flex" alignItems="center" height={{ md: '7%', xs: '40px' }}>
                            <IconButton onClick={togglePlayPause} color="inherit">
                                {playing ? <FaPause color={theme.palette.secondary.main} /> :
                                    <FaPlay color={theme.palette.secondary.main} />}
                            </IconButton>

                            <Slider
                                value={played}
                                onChange={handleSeekChange}
                                aria-labelledby="progress-slider"
                                min={0}
                                max={1}
                                step={0.01}
                                sx={{ flex: 1, mx: 2, color: theme.palette.secondary.main }}
                            />

                            <IconButton onClick={() => setMuted(!muted)} color="inherit">
                                {muted ? <FaVolumeMute color={theme.palette.secondary.main} /> :
                                    <FaVolumeUp color={theme.palette.secondary.main} />}
                            </IconButton>

                            <Slider
                                value={muted ? 0 : volume}
                                onChange={handleVolumeChange}
                                aria-labelledby="volume-slider"
                                min={0}
                                max={1}
                                step={0.1}
                                sx={{ width: '100px', mx: 2, color: theme.palette.secondary.main }}
                            />

                            {/* <IconButton color="inherit">
                                <FaExpand color={theme.palette.secondary.main} />
                            </IconButton> */}
                        </Box>
                    </Grid2>
                </Grid2>
            </Box>
        </Box>
    );
}
