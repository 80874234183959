import { Typography, Box, Grid2, Modal, Button } from '@mui/material'
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Navbar from '../navbar'
import { useLocation } from 'react-router-dom';
import { ArticleHeading, ArticleSubheading, ArticleBody, ArticleQuote } from './ArticleTypography';


export default function RoleOfTransactiveMemory() {
    const theme = useTheme();
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const elementId = location.hash.substring(1);
            const element = document.getElementById(elementId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <Box sx={{ 'backgroundColor': theme.palette.background.default }} id="header">

            <Navbar />
            <Box minHeight={'100vh'}>

                {/*Content*/
                }
                <Grid2 container sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    mt: '100px',
                    color: theme.palette.text.primary
                }}>
                    <Grid2 size={12} sx={{ padding: { xs: '10px', sm: '20px' }, textAlign: 'center' }}>
                        <Typography variant="h2" component="h2" sx={{
                            mb: 4,
                            fontSize: { xs: '2rem', sm: '3.75rem' }
                        }}>
                            The Role of Transactive Memory Systems in Enhancing <span style={{ color: theme.palette.primary.main }}>Organisational Performance</span>
                        </Typography>
                        <Typography variant="h6" component="h2" sx={{
                            fontSize: { xs: '1rem', sm: '1.25rem' }
                        }}>
                            Daniel Lambert | 18th November 2024 | <a href='/research/role_of_transactive_memory#tldr' style={{ color: theme.palette.primary.dark }}>TL;DR</a>
                        </Typography>


                        <ArticleHeading>Introduction</ArticleHeading>
                        <ArticleBody>
                            The efficient management of knowledge has become a cornerstone of organisational success in the modern era. As tasks grow increasingly complex, companies must adopt systems that enhance knowledge sharing, storage, and retrieval. Transactive Memory Systems (TMS) represent a theoretical and practical framework for achieving this, offering organisations a scalable approach to managing distributed knowledge across teams. Rooted in psychological theory, TMS has been validated by decades of research, demonstrating its potential to drive creativity, productivity, and innovation.
                            This article explores the principles of TMS, its benefits for organisations, challenges in implementation, and how digital solutions can enhance its scalability.
                        </ArticleBody>

                        <ArticleHeading>Principles of Transactive Memory Systems</ArticleHeading>
                        <ArticleBody>
                            Transactive memory theory, first proposed by psychologist Daniel Wegner, describes how groups encode, store, and retrieve shared knowledge. It emphasises the division of cognitive labour, where each team member specialises in a specific domain of expertise, forming a network of knowledge [1][2].
                        </ArticleBody>

                        <ArticleSubheading>Encoding</ArticleSubheading>
                        <ArticleBody>
                            The encoding process involves individuals understanding what knowledge resides with other members. Over time, teams develop a mental map of each other’s expertise. This relational awareness allows for quick identification of who knows what, streamlining future information retrieval [1].
                        </ArticleBody>

                        <ArticleSubheading>Storage</ArticleSubheading>
                        <ArticleBody>
                            Knowledge is assigned to specific subject-matter experts (SMEs) within the team. For example, technical knowledge might reside with engineers, while market insights are stored with sales personnel. This differentiation ensures efficient storage and retrieval without redundancy [1][5].
                        </ArticleBody>

                        <ArticleSubheading>Retrieval</ArticleSubheading>
                        <ArticleBody>
                            Retrieval is the process of locating and accessing stored knowledge. Team members typically identify the SME through direct queries or collective discussions. If internal expertise is unavailable, external research or consultation is undertaken [1]. Wegner’s experiments illustrated that structured retrieval systems significantly outperform unstructured ones, underscoring the importance of assigning expertise within teams [2].
                        </ArticleBody>
                        <ArticleSubheading>Group Cognition and Creativity</ArticleSubheading>
                        <ArticleBody>
                            Studies have confirmed that well-developed TMS promotes effective knowledge sharing and idea elaboration, fostering creativity. Teams with diverse expertise are more likely to engage in divergent thinking, apply knowledge innovatively, and avoid groupthink [3][5]. This dynamic broadens the range of inputs during problem-solving, improving both decision-making quality and communication. By facilitating divergent thinking and reducing groupthink, TMS enables teams to generate innovative solutions and apply existing knowledge in novel ways [3][5].
                        </ArticleBody>

                        <ArticleSubheading>Knowledge Differentiation</ArticleSubheading>
                        <ArticleBody>
                            An essential aspect of TMS is the differentiated nature of knowledge—"What don't I know, and who does know?" This differentiation allows teams to leverage specialised expertise rather than overloading individuals with all-encompassing responsibilities [4].
                        </ArticleBody>
                        <ArticleQuote author="Samuel Johnson">
                            Knowledge is of two kinds: we know a subject ourselves, or we know where we can find information upon it.
                        </ArticleQuote>

                        <ArticleHeading>Theoretical Insights</ArticleHeading>
                        <ArticleSubheading>Group Cognition and Creativity</ArticleSubheading>
                        <ArticleBody>
                            Studies have confirmed that well-developed TMS promotes effective knowledge sharing and idea elaboration, fostering creativity. Teams with diverse expertise are more likely to engage in divergent thinking, apply knowledge innovatively, and avoid groupthink [3][5]. This dynamic broadens the range of inputs during problem-solving, improving both decision-making quality and communication. By facilitating divergent thinking and reducing groupthink, TMS enables teams to generate innovative solutions and apply existing knowledge in novel ways [3][5].
                        </ArticleBody>
                        <ArticleSubheading>Knowledge Differentiation</ArticleSubheading>
                        <ArticleBody>
                            An essential aspect of TMS is the differentiated nature of knowledge—"What don’t I know, and who does know?" This differentiation allows teams to leverage specialised expertise rather than overloading individuals with all-encompassing responsibilities [4]. As Samuel Johnson noted, "Knowledge is of two kinds: we know a subject ourselves, or we know where we can find information upon it."
                        </ArticleBody>

                        <ArticleHeading>Organisational Performance</ArticleHeading>
                        <ArticleBody>
                            TMS play a critical role in enhancing organisational performance by enabling teams to leverage their collective expertise more effectively. Teams with well-established TMS demonstrate superior outcomes across key performance dimensions:
                        </ArticleBody>
                        <ArticleQuote author="Daniel Bachrach, et al.">Teams with a prior domain knowledge tend to perform better, compared with teams with less such knowledge</ArticleQuote>
                        <ArticleSubheading>Task Performance</ArticleSubheading>
                        <ArticleBody>Robust TMS structures ensure that tasks are assigned to the most qualified team members, enabling efficient execution and minimising errors. Meta-analyses consistently show that teams with structured TMS outperform those without such systems, particularly in high-demand, knowledge-intensive environments [5].</ArticleBody>
                        <ArticleSubheading>Affective Performance</ArticleSubheading>
                        <ArticleBody>Beyond task execution, TMS contributes to team member satisfaction and communication by fostering clarity in roles and responsibilities. This clarity not only reduces misunderstandings but also strengthens collaboration and trust, creating a positive work environment that drives long-term organisational success [5].</ArticleBody>
                        <ArticleSubheading>Capability Building and Expertise Alignment</ArticleSubheading>
                        <ArticleBody>Pre-existing domain knowledge significantly enhances team effectiveness, as it provides ready access to task-critical information. This underscores the importance of continuous capability building and aligning expertise within teams to maintain a competitive edge [5].</ArticleBody>


                        <ArticleHeading>Challenges in TMS Implementation</ArticleHeading>
                        <ArticleBody>Despite its advantages, TMS is not without challenges. Research indicates that goal misalignment among team members can lead to information hoarding, diminishing overall performance [4]. Transparency and democratisation of knowledge are critical to counteracting these tendencies. Additionally, social memory systems are inherently limited in scale. As team sizes grow, the ability to manage distributed knowledge diminishes, necessitating formal systems for tracking and retrieval [2].</ArticleBody>

                        <ArticleHeading>Scaling TMS with Digital Solutions</ArticleHeading>
                        <ArticleBody>Digital platforms for TMS offer a promising solution to the scalability problem. By codifying relationships and knowledge maps, these tools create accessible databases that mimic the relational structures of traditional TMS. They provide:</ArticleBody>
                        <ArticleSubheading>Centralised Knowledge Repositories</ArticleSubheading>
                        <ArticleBody>Ensuring that critical information is always accessible, even when SMEs are unavailable.</ArticleBody>
                        <ArticleSubheading>Enhanced Retrieval Systems</ArticleSubheading>
                        <ArticleBody>Allowing for efficient searches based on topics or expertise areas.</ArticleBody>
                        <ArticleSubheading>Improved Collaboration Tools</ArticleSubheading>
                        <ArticleBody>Supporting cross-functional teams in diverse locations to share knowledge seamlessly.</ArticleBody>
                        <ArticleBody>Digital TMS such as OrgAtlas encourage the democratisation of knowledge, alignment of organisational goals, and mitigation of inefficiencies caused by informal memory processes [4][5].</ArticleBody>

                        <ArticleHeading>Conclusion</ArticleHeading>
                        <ArticleBody>Transactive Memory Systems represent a transformative approach to managing organisational knowledge. By fostering shared understanding, leveraging differentiated expertise, and enabling structured collaboration, TMS significantly enhances team creativity, task performance, and satisfaction. However, organisations must address challenges like goal misalignment and scalability through transparent practices and digital solutions.
                            In an increasingly knowledge-driven economy, embracing TMS is not just a theoretical advantage but a practical necessity for sustained success.
                        </ArticleBody>


                        <ArticleHeading>References</ArticleHeading>
                        <ArticleBody>
                            [1] Wikipedia contributors, "Transactive memory," Wikipedia. Available: https://en.wikipedia.org/wiki/Transactive_memory.
                        </ArticleBody>
                        <ArticleBody>
                            [2] D. Wegner, R. Erber, and P. Raymond, "Transactive Memory in Close Relationships," Journal of Personality and Social Psychology, 1991. Available: https://dtg.sites.fas.harvard.edu/DANWEGNER/pub/Wegner,Erber,&Raymond1991.pdf.
                        </ArticleBody>
                        <ArticleBody>
                            [3] C. Mesmer-Magnus and L. DeChurch, "Information sharing and team performance: A meta-analysis," Journal of Organizational Behavior. Available: https://onlinelibrary.wiley.com/doi/full/10.1002/job.2535.
                        </ArticleBody>
                        <ArticleBody>
                            [4] H. Bunderson and J. Sutcliffe, "Group cognition and team performance," Management Science. Available: https://web.p.ebscohost.com/ehost/pdfviewer/pdfviewer?vid=0&sid=38fa664f-ce13-49d0-ba5f-e6931e895f85%40redis.
                        </ArticleBody>
                        <ArticleBody>
                            [5] A. Austin and J. L. Klimoski, "The relationship of TMS to team innovation and performance," Organizational Psychology Review. Available: https://oce.ovid.com/article/00004565-201903000-00009/PDF.
                        </ArticleBody>

                        <Box height={'100px'} id='tldr'></Box>
                        <ArticleHeading>TL;DR</ArticleHeading>
                        <ArticleBody>Transactive Memory Systems (TMS) enhance organisational performance by enabling teams to collaboratively encode, store, and retrieve knowledge. Rooted in organisational psychology research, TMS fosters efficiency, creativity, and innovation by assigning expertise, improving communication, and leveraging diverse knowledge. Studies confirm that well-developed TMS boosts task performance, team satisfaction, and problem-solving while mitigating groupthink. Though challenges like goal misalignment and scalability exist, digital TMS platforms offer scalable solutions to democratise knowledge and streamline collaboration, making TMS indispensable in today's knowledge-driven economy.</ArticleBody>
                        <Box height={'100px'}></Box>

                    </Grid2>
                </Grid2>
            </Box>



        </Box >
    );
}