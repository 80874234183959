import * as React from 'react';
import { Card, Modal, Typography, TextField, FormControl, FormLabel, Button, Box, Grid2 } from '@mui/material'
import { useTheme } from "@mui/material/styles";

export default function Mission() {
    const theme = useTheme();

    return (
        <Box minHeight={'100vh'} sx={{
            display: 'flex-block', justifyContent: "center",
            'backgroundColor': theme.palette.background.default, position: 'relative', alignItems: 'center'
        }} id='mission'>

            {/*Content*/}
            <Grid2 container spacing={{ xs: 8 }} sx={{ color: theme.palette.text.primary, pt: '130px' }}>
                <Grid2 size={{ xs: 12, md: 6 }} sx={{ padding: '20px', textAlign: 'center' }}>
                    <Typography variant="h2" component="h2" sx={{ mb: 4 }}>
                        Our <span style={{ color: theme.palette.primary.main }}>Mission</span>
                    </Typography>
                    <Typography variant="h4" component="h2" fontSize={'20pt'}
                        sx={{ mb: 2, color: theme.palette.primary.dark }}>
                        Unlock The Knowledge Within Organisations
                    </Typography>
                    <Typography variant="h6" component="h2" sx={{ maxWidth: '70%', margin: '0 auto' }}>
                        By connecting the individual to the resources of the group.
                    </Typography>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }} sx={{ textAlign: 'start' }}>

                    {/*Introduction*/}
                    <Box sx={{
                        maxWidth: '70%',
                        margin: '0 auto',
                        position: 'relative',
                    }}>

                        <Typography variant="h3" component="h2"
                            sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            efficiency.
                        </Typography>
                        <Typography variant="body1" component="h2"
                            sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            Find where knowledge lives. Quickly.
                        </Typography>

                        <Typography variant="h3" component="h2"
                            sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            right fit.
                        </Typography>
                        <Typography variant="body1" component="h2"
                            sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            OrgAtlas points you in the right direction when you're wondering "who to talk to?", "where to find that?", "who's worked on this?" or "have we done this before?"
                        </Typography>

                        <Typography variant="h3" component="h2"
                            sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            transparency.
                        </Typography>
                        <Typography variant="body1" component="h2"
                            sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            OrgAtlas encourages transparency and collaboration through accessible mapping of resources.
                        </Typography>


                        <Typography variant="h3" component="h2"
                            sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            reduce rework.
                        </Typography>
                        <Typography variant="body1" component="h2"
                            sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            The self updating fabric of OrgAtlas learns and adapts the knowledge graph to keep track of changes in your organisation.
                            As you and your company grows, so does OrgAtlas.
                        </Typography>

                        <Typography variant="h3" component="h2"
                            sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            current.
                        </Typography>
                        <Typography variant="body1" component="h2"
                            sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            The self updating fabric of OrgAtlas learns and adapts the knowledge graph to keep track of changes in your organisation.
                            As you and your company grows, so does OrgAtlas.
                        </Typography>


                    </Box>
                </Grid2>
            </Grid2>
        </Box>
    )
        ;
}