import { Box, Grid2, Typography, IconButton, Drawer, List, ListItem, Collapse } from "@mui/material";
import { Link } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import OrgAtlas from '../assets/logo.png';
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { FaBars } from 'react-icons/fa';
import DemoModal from './DemoModal';

interface NavLinkProps {
    to: string;
    title: string;
    selected: string;
    setSelected: Function;
}

const NavLink = (props: NavLinkProps) => {
    const theme = useTheme();

    return (
        <Grid2 sx={{ marginLeft: '60px' }}>
            <Link
                to={props.to}
                style={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color: props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary,
                    transition: 'color 0.8s',
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.color = theme.palette.primary.main;
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.color = props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary;
                }}
                onClick={() => {
                    props.setSelected(props.title)
                }}
            >
                <Typography variant="h4" fontSize="16pt">
                    {props.title}
                </Typography>
            </Link>
        </Grid2>
    );
};

const PageLink = (props: NavLinkProps) => {
    const theme = useTheme();

    return (
        <Grid2 sx={{ marginLeft: '60px' }}>
            <a
                href={props.to}
                style={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color: props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary,
                    transition: 'color 0.8s',
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.color = theme.palette.primary.main;
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.color = props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary;
                }}
                onClick={() => {
                    props.setSelected(props.title)
                }}
            >
                <Typography variant="h4" fontSize="16pt">
                    {props.title}
                </Typography>
            </a>
        </Grid2>
    );
};

interface NavbarProps {
    // Remove setOpen since we'll handle it internally
}

// Add new interface for menu items
interface MenuItem {
    title: string;
    to: string;
    isPage: boolean;
    subItems?: {
        title: string;
        to: string;
    }[];
}

const NavDropdown = (props: NavLinkProps & { subItems: MenuItem['subItems'] }) => {
    const theme = useTheme();
    const [isChildSelected, setIsChildSelected] = useState(false);

    useEffect(() => {
        // Update isChildSelected whenever selected prop changes
        const childSelected = props.subItems?.some(item => item.title === props.selected);
        setIsChildSelected(!!childSelected);
    }, [props.selected, props.subItems]);

    return (
        <Grid2
            sx={{
                marginLeft: '60px',
                position: 'relative',
                '&:hover': {
                    '& .submenu': {
                        display: 'block'
                    }
                }
            }}
        >
            <Link
                to={props.to}
                style={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color: (props.selected === props.title || isChildSelected) ? theme.palette.primary.main : theme.palette.text.primary,
                    transition: 'color 0.8s',
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.color = theme.palette.primary.main;
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.color = (props.selected === props.title || isChildSelected) ? theme.palette.primary.main : theme.palette.text.primary;
                }}
                onClick={() => props.setSelected(props.title)}
            >
                <Typography variant="h4" fontSize="16pt">
                    {props.title}
                </Typography>
            </Link>
            <Box
                sx={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    height: '15px',
                    width: '100%'
                }}
            />
            <Box
                className="submenu"
                sx={{
                    display: 'none',
                    position: 'absolute',
                    top: 'calc(100% + 15px)',
                    left: 0,
                    backgroundColor: theme.palette.background.default,
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    borderRadius: '4px',
                    padding: '8px 0',
                    zIndex: 1000,
                    minWidth: '200px',
                }}
            >
                {props.subItems?.map((item) => (
                    <Link
                        key={item.title}
                        to={item.to}
                        style={{
                            textDecoration: 'none',
                            color: theme.palette.text.primary,
                            display: 'block',
                            padding: '8px 16px',
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.color = theme.palette.primary.main;
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.color = theme.palette.text.primary;
                        }}
                        onClick={() => props.setSelected(item.title)}
                    >
                        <Typography fontSize="14pt">
                            {item.title}
                        </Typography>
                    </Link>
                ))}
            </Box>
        </Grid2>
    );
};

export interface NavbarHandle {
    openDemoModal: () => void;
}

const Navbar = forwardRef<NavbarHandle>((props, ref) => {
    const [scrolled, setScrolled] = useState(false);
    const [selected, setSelected] = useState('');
    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme();
    const [expandedItems, setExpandedItems] = useState<string[]>([]);
    const [demoModalOpen, setDemoModalOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // Update menuItems array in Navbar component
    const menuItems: MenuItem[] = [
        { title: 'Home', to: '/#home', isPage: false },
        { title: 'Mission', to: '/#mission', isPage: true },
        { title: 'Privacy', to: '/#privacy', isPage: true },
        {
            title: 'Research',
            to: '#',
            isPage: false,
            subItems: [
                { title: 'Transactive Memory', to: '/research/role_of_transactive_memory#header' },
            ]
        },
        { title: 'Tutorials', to: '/#tutorials', isPage: false },
    ];

    useEffect(() => {
        const path = window.location.pathname;
        const hash = window.location.hash;

        //default to home
        setSelected('Home');

        // First check for exact matches in menuItems
        const exactMatch = menuItems.find(item => item.to === path || item.to === hash);
        if (exactMatch) {
            setSelected(exactMatch.title);
            return;
        }

        // Then check for sub
        for (const item of menuItems) {
            if (item.subItems) {
                const subItemMatch = item.subItems.find(subItem => subItem.to === path || subItem.to === hash || subItem.to === path + hash);
                if (subItemMatch) {
                    setSelected(item.title);
                    return;
                }
            }
        }
    }, []);

    const toggleExpanded = (title: string) => {
        setExpandedItems(prev =>
            prev.includes(title)
                ? prev.filter(item => item !== title)
                : [...prev, title]
        );
    };

    const drawer = (
        <List>
            {menuItems.map((item) => (
                <ListItem
                    key={item.title}
                    sx={{
                        padding: '16px',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}
                >
                    {item.subItems ? (
                        <>
                            <Box
                                onClick={() => toggleExpanded(item.title)}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    cursor: 'pointer'
                                }}
                            >
                                <NavLink
                                    to={item.to}
                                    title={item.title}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Typography sx={{ marginLeft: 1 }}>
                                    {expandedItems.includes(item.title) ? '▼' : '▶'}
                                </Typography>
                            </Box>
                            <Collapse in={expandedItems.includes(item.title)} timeout="auto" unmountOnExit>
                                <List sx={{
                                    pl: 2,
                                    padding: '8px 0'
                                }}>
                                    {item.subItems.map((subItem) => (
                                        <ListItem key={subItem.title} sx={{ padding: '8px 0' }}>
                                            <NavLink
                                                to={subItem.to}
                                                title={subItem.title}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </>
                    ) : item.isPage ? (
                        <PageLink to={item.to} title={item.title} selected={selected} setSelected={setSelected} />
                    ) : (
                        <NavLink to={item.to} title={item.title} selected={selected} setSelected={setSelected} />
                    )}
                </ListItem>
            ))}
            <ListItem sx={{ padding: '16px' }}>
                <Typography
                    variant="h5"
                    component="button"
                    sx={{
                        width: '100%',
                        color: theme.palette.text.secondary,
                        cursor: 'pointer',
                        backgroundColor: theme.palette.secondary.main,
                        border: 'none',
                        padding: '8px 24px',
                        borderRadius: '10px',
                        fontSize: '16pt',
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        }
                    }}
                    onClick={() => setDemoModalOpen(true)}
                >
                    Book a Demo
                </Typography>
            </ListItem>
        </List>
    );

    useImperativeHandle(ref, () => ({
        openDemoModal: () => setDemoModalOpen(true)
    }));

    return (
        <Grid2
            container
            sx={{
                padding: '10px 20px',
                alignItems: 'center',
                position: 'sticky',
                top: 0,
                backgroundColor: theme.palette.background.default,
                boxShadow: scrolled ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : 'none',
                zIndex: 1000,
            }}
        >
            <Grid2 size={0.5}></Grid2>
            <Grid2 size={2}>
                <Box
                    sx={{
                        height: '60px',
                        maxWidth: '80%',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '4px',
                    }}
                >
                    <img src={OrgAtlas} style={{ height: '80px' }} alt="OrgAtlas" />
                </Box>
            </Grid2>
            <Grid2 size={8.5} container sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
                display: { xs: 'none', md: 'flex' }
            }}>
                {menuItems.map((item) => (
                    item.subItems ? (
                        <NavDropdown
                            key={item.title}
                            to={item.to}
                            title={item.title}
                            selected={selected}
                            setSelected={setSelected}
                            subItems={item.subItems}
                        />
                    ) : item.isPage ? (
                        <PageLink
                            key={item.title}
                            to={item.to}
                            title={item.title}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    ) : (
                        <NavLink
                            key={item.title}
                            to={item.to}
                            title={item.title}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    )
                ))}
                <Typography
                    variant="h5"
                    component="button"
                    sx={{
                        marginLeft: '60px',
                        color: theme.palette.text.secondary,
                        cursor: 'pointer',
                        backgroundColor: theme.palette.secondary.main,
                        border: 'none',
                        padding: '8px 24px',
                        borderRadius: '10px',
                        fontSize: '16pt',
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        }
                    }}
                    onClick={() => setDemoModalOpen(true)}
                >
                    Book a Demo
                </Typography>
            </Grid2>
            <Grid2 sx={{
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'flex-end',
                flex: 1
            }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                >
                    <FaBars />
                </IconButton>
            </Grid2>
            <Grid2 size={0.5}></Grid2>

            <Drawer
                variant="temporary"
                anchor="right"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better mobile performance
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: 280,
                        backgroundColor: theme.palette.background.default,
                    },
                }}
            >
                {drawer}
            </Drawer>

            <DemoModal
                open={demoModalOpen}
                onClose={() => setDemoModalOpen(false)}
            />
        </Grid2>
    )
})

export default Navbar;